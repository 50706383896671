<template>
  <div 
    v-loading="isLoadingMore || isLoadingDefault" 
    id="public-search" 
    class="padding-btm-channel-link"
  >
    <el-row type="flex" class="search-row">
      <el-input
        class="search-input"
        size="mini"
        :placeholder="$t('SEARCH_NEWSILY')"
        v-model="searchKeyword"
        @keyup.enter.native="doSearch"
      >
        <div
          v-if="searchKeyword !== ''"
          class="clear-text"
          slot="suffix"
          @click="searchKeyword = ''; doSearch()">
          <span>{{ $t("CLEAR") }}</span>
          <i class="custom-icon icon-clear mini"/>
        </div>
      </el-input>
      <div class="search-button" @click="doSearch()">
        <i class="custom-icon icon-header-search white-icon mini"></i>
      </div>
    </el-row>

    <el-row :type="isMobile ? '' : 'flex'" justify="space-between" class="result-summary-filter-row">
      <span v-html="searchedKeyword ? $t('RESULT_SUMMARY', resultSummaryTransObj) : null" />
      <div>
        <span>{{ $t("FILTER_BY") }}</span>
        <el-select v-model="selectedCategoryId" @change="doSearch">
          <el-option
            v-for="item in allCategory"
            :key="item.id"
            :label="item.label ? item.label : getNameFromTransList(item)"
            :value="item.id"
          />
        </el-select>
      </div>
    </el-row>

    <el-divider />

    <template v-if="totalAvailable == 0 && !isLoadingMore">
      <div class="no-result">{{ $t("NO_RESULT") }}</div>
    </template>

    <template v-else>
      <transition-group name="slide-from-right">
        <el-row 
          class="all-articles" 
          :type="isMobile ? '' : 'flex'" 
          justify="center" 
          v-for="(row, index) in allResult"
          :key="arbitraryId + 'row' + index"
          :gutter="isMobile ? 0 : 30"
        >
          <el-col 
            :span="isMobile ? 24 : 12"
            v-for="(el, index2) in row"
            :key="arbitraryId+'row'+index+'el'+index2"
          >
            <article-summary 
              v-if="el !== 'filling'"
              orientation="landscape"
              size="small"
              :articleObj="el"
              hideName
              withWhiteBg withShadow
              clickable
            />
          </el-col>
        </el-row>
      </transition-group>

      <div id="page-bottom-to-load-more"/>
    </template>

  </div>
</template>

<script>
import ArticleSummary from "./components/article-summary.vue"
import { mapState } from "vuex";
import debounce from 'lodash/debounce'
import { getArticleList } from "@/api/article"
import { getAllCategory } from "@/api/category"
import { generalMixin } from "@/utils/general-mixin.js"
import { splitArrayToChunk, escapeHtml } from "@/utils/helpers"

export default {
  name: "ArticleByCategory",
  components: {
    ArticleSummary,
  },
  computed: {
    ...mapState("app", ["isMobile"]),
    resultSummaryTransObj() {
      return {
        totalLoaded: this.totalLoaded,
        totalAvailable: this.totalAvailable,
        keyword: escapeHtml(this.searchedKeyword),
      }
    }
  },
  mixins: [ generalMixin ],
  data() {
    return {
      searchKeyword: "",
      searchedKeyword: "",
      allResult: [],
      totalLoaded: 0,
      totalAvailable: 1,
      selectedCategoryId: null,
      isLoadingMore: false,
      isLoadingDefault: false,
      allCategory: [],
      arbitraryId: 0,

    }
  },
  watch: {
    '$route.query.keyword': {
      handler() {
        this.mountedFn()
      }
    }
  },
  methods: {
    async getArticle(amount = 1) {
      let offset = this.totalLoaded
      const postData = {
        offset,
        limit: amount,
        published: true,
        search: this.searchKeyword,
        categoryActiveOnly: true
      }
      if (this.selectedCategoryId) {
        postData.category = { id: this.selectedCategoryId }
      }
      this.searchedKeyword = this.searchKeyword
      let result = []
      await getArticleList(postData).then(res => {
        if (res.result?.list != null) {
          result = res.result.list
          this.totalAvailable = res.result.total
          this.totalLoaded += result.length
        }
      })
      return result
    },
    onScroll: debounce(function () {
      const tolerance = 900 // load before reaching
      const idYPosition = document.getElementById("page-bottom-to-load-more").getBoundingClientRect().top;
      if (idYPosition - tolerance < 0) {
        if (this.totalAvailable > this.totalLoaded && !this.isLoadingDefault) {
          this.loadMore()
        }
      }
    }, 100),
    async loadMore() {
      if (this.isLoadingMore) return
      const loadNum = 10

      this.isLoadingMore = true
      const result = await this.getArticle(loadNum)

      splitArrayToChunk(result, 2).forEach(row => {
        if (row.length === 1) row.push("filling")
        this.allResult.push(row)
      })

      this.isLoadingMore = false
    },
    reset() {
      this.allResult = []
      this.totalAvailable = 1
      this.totalLoaded = 0
      this.arbitraryId++
    },
    getCategory() {
      const postData = {
        genericRecord: {
          status: "ACTIVE",
          type: "category"
        }
      }
      getAllCategory(postData).then(res => {
        if (res.result) {
          this.allCategory = res.result.list
          this.allCategory.unshift({
            id: null,
            label: this.$t("ALL")
          })
        }
      })
    },
    async mountedFn() {
      this.searchKeyword = this.$route.query.keyword
      if (this.$route.query.categoryId) {
        this.selectedCategoryId = Number(this.$route.query.categoryId)
      }
      this.reset()
      this.isLoadingDefault = true
      await this.loadMore()
      this.isLoadingDefault = false
    },
    async doSearch() {
      this.$router.push({
        path: "/search",
        query: {
          keyword: this.searchKeyword,
          categoryId: this.selectedCategoryId
        }
      }).catch(() => {})
      this.mountedFn()
    }
  },
  async mounted() {
    await this.getCategory()
    this.mountedFn()
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#public-search {
  padding-top: 2rem;

  .search-row {
    .search-input {
      .el-input__inner {
        height: 48px;
        background-color: $white;
        border: 1px solid #70707040;
        font-size: 13px;
        width: calc(100% + 10px);
      }
    }
    .search-button {
      width: 52px;
      height: 48px;
      border-radius: 5px;
      background-color: $black;
      display: table;
      position: relative;
      left: -5px;
      margin: 0;
      z-index: 2;
      cursor: pointer;
      i {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
    }
    .clear-text {
      font-size: 13px;
      height: 35px;
      line-height: 35px;
      display: table;
      margin-right: 10px;
      cursor: pointer;
      i {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
      span {
        margin-right: 5px;
      }
    }
  }

  .result-summary-filter-row {
    margin: 2rem 0 3rem 0;
    .el-select {
      margin-left: 1rem;
    }
  }

  .no-result {
    font-size: 2rem;
    font-weight: bold;
    margin: auto;
    height: 25vh;
    width: 100%;
    width: 100vw;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  .search-keyword-row {
    span {
      margin: auto;
      white-space: nowrap;
    }
    .el-divider {
      margin: 1.7rem 0.5rem;
    }
  }
  .all-articles {
    >* {
      margin: 1rem 0;
      width: 100%;
    }
  }
  .load-more {
    font-size: 18px;
    line-height: 98px;
  }

  // Transition
  .row-enter-active, .row-leave-active {
    transition: all 1s;
  }
  .row-enter, .row-leave-to /* .row-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
}

.mobile {
  #public-search {
    .all-articles {
      >* {
        margin: 0;
        margin-bottom: 0.5rem;
      }
    }
    .result-summary-filter-row {
      .el-select {
        margin-top: 1rem;
      }
    }
  }
}
</style>